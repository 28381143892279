// src/components/Navigation/Navigation.tsx

import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './Navigation.module.sass';
import homeIcon from '../../assets/images/home_nav_icon.svg';
import leaderboardIcon from '../../assets/images/leaderboard_nav_icon.svg';
import friendsIcon from '../../assets/images/friends_nav_icon.svg';
import earnIcon from '../../assets/images/earn_nav_icon.svg';

const Navigation: React.FC = () => {
    return (
        <nav className={styles.navigation}>
            <NavLink to="/" className={({ isActive }) => isActive ? styles.active : ''}>
                <img src={homeIcon} alt="Home" />
                <p>Home</p>
            </NavLink>
            <NavLink to="/leaderboard" className={({ isActive }) => isActive ? styles.active : ''}>
                <img src={leaderboardIcon} alt="Leaderboard" />
                <p>Leaderboard</p>
            </NavLink>
            <NavLink to="/friends" className={({ isActive }) => isActive ? styles.active : ''}>
                <img src={friendsIcon} alt="Friends" />
                <p>Friends</p>
            </NavLink>
            <NavLink to="/earn" className={({ isActive }) => isActive ? styles.active : ''}>
                <img src={earnIcon} alt="Earn" />
                <p>Earn</p>
            </NavLink>
        </nav>
    );
};

export default Navigation;
