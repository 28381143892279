// src/pages/Home/Home.tsx

import React from 'react';
import styles from './Friends.module.sass';
import FriendsList from '../../components/FriendsList/FriendsList';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

const friendsData = [
    { id: 1, name: 'jebbertjan', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 2, name: 'sharika88', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 3, name: 'joepie', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 4, name: 'newfriend', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 5, name: 'jebbertjan', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 6, name: 'sharika88', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 7, name: 'joepie', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 8, name: 'newfriend', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 9, name: 'jebbertjan', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 10, name: 'sharika88', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 11, name: 'joepie', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
    { id: 12, name: 'newfriend', tokens: 1000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ' },
];

const totalTokens = friendsData.reduce((total, friend) => total + friend.tokens, 0);

const Friends: React.FC = () => {
    return (
        <div className={styles.friends_page_wrapper}>
            <div className="page_header">
                <h2 className="page_header_title">Invite your friends</h2>
                <p className="page_header_description">Receive 1000 chewy for every friend that you refer and starts playing. Total earned will be added to your high score</p>
            </div>
            <div className={styles.friends_list_wrapper}>
                <FriendsList friends={friendsData} totalTokens={totalTokens} />
            </div>
            <div className={styles.inv_button}>
                <PrimaryButton type="primary_border" className={styles.inv_button_styles}>
                    Invite frends
                </PrimaryButton>
            </div>
        </div>
    );
};

export default Friends;
