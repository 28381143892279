// src/components/TaskModal/TaskModal.tsx

import React from 'react';
import styles from './TaskModal.module.sass';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

interface TaskModalProps {
    isOpen: boolean;
    onClose: () => void;
    icon: string;
    title: string;
    description: string;
    reward: string;
    onJoin: () => void;
    onCheck: () => void;
}

const TaskModal: React.FC<TaskModalProps> = ({ isOpen, onClose, icon, title, description, reward, onJoin, onCheck }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.overlay}>
            <div className={styles.modal}>
                <button className={styles.closeButton} onClick={onClose}></button>
                <img src={icon} alt="" className={styles.icon} />
                <h2 className={styles.title}>{title}</h2>
                <p className={styles.description}>{description}</p>
                <PrimaryButton type="primary" onClick={onJoin} className="min_font">
                    JOIN TELEGRAM CHANNEL
                </PrimaryButton>


                <div className={styles.reward}>+{reward}</div>
                <p className={styles.checkDescription}>
                    WHEN YOU COMPLETED THE TASK PRESS "CHECK" FOR OUR MODERATION TEAM TO CHECK IF YOU ACTUALLY COMPLETED IT
                </p>
                <PrimaryButton type="primary" onClick={onCheck} className="min_font">
                    CHECK
                </PrimaryButton>
            </div>
        </div>
    );
};

export default TaskModal;
