import React, { useState } from 'react';
import styles from './ConnectWallet.module.sass';
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import connectWalletImage from '../../assets/images/connect_wallet_image.png';
import placeWalletImage from '../../assets/images/place_wallet_image.jpg';

interface ConnectWalletProps {
    onConnect: () => void;
    onSkip?: () => void;
}

const ConnectWallet: React.FC<ConnectWalletProps> = ({ onConnect, onSkip }) => {
    const [isWalletConnected, setIsWalletConnected] = useState(false);

    const handleConnect = () => {
        onConnect();
        setIsWalletConnected(true);
    };

    return (
        <div className={styles.connect_wallet_wrapper}>
            <img
            src={isWalletConnected ? placeWalletImage : connectWalletImage}
            alt={isWalletConnected ? "Place Bet" : "Connect Wallet"}
            className={`${styles.character_image} ${styles.primary}`}
            />
            <h2 className={styles.title}>
            {isWalletConnected ? "Place Your Bet" : "Connect Your Wallet"}
            </h2>
            <p className={styles.description}>
            {isWalletConnected
                ? <>For every bet you get 1 try to set a high score in the current season. <br/><br/> A season lasts one week.</>
                : <>You need to have a TON wallet set up in your Telegram account in order to place a bet. </>}
            </p>
            <PrimaryButton
            type={isWalletConnected ? "secondary_green" : "primary_border"}
            onClick={handleConnect}
            className="max_width min_font"
            >
            {isWalletConnected ? "Place $0.10 Bet" : "Connect or Setup TON Wallet"}
            </PrimaryButton>
            {!isWalletConnected && (
            <button onClick={onSkip} className={styles.skip_button}>
                Connect Later
            </button>
            )}
        </div>
    );
};

export default ConnectWallet;
