// src/components/Leaderboard/Leaderboard.tsx

import React from 'react';
import styles from './LeaderboardTable.module.sass';

interface Participant {
    id: number;
    name: string;
    score: number;
    avatar: string;
    position: number;
}

interface LeaderboardTableProps {
    participants: Participant[];
    totalParticipants: string;
}

const LeaderboardTable: React.FC<LeaderboardTableProps> = ({ participants, totalParticipants }) => {
    return (
        <div className={styles.leaderboard}>
            <div className={styles.header}>
                <h2 className={styles.heading_tasks}>LEADERBOARD</h2>
                <span className={styles.total_participants}>{totalParticipants} PARTICIPANTS</span>
            </div>
            <div className={styles.participant_list}>
            {participants.map((participant, index) => (
                <div className={styles.participant} key={participant.id}>
                    <img src={participant.avatar} alt={`${participant.name}'s avatar`} className={styles.avatar} />
                    <div className={styles.info}>
                        <span className={styles.name}>{participant.name}</span>
                        <span className={styles.score}>{participant.score.toLocaleString()} CHEWY</span>
                    </div>
                    <div className={styles.position}>
                        {index === 0 ? '🥇' : index === 1 ? '🥈' : index === 2 ? '🥉' : `#${participant.position}`}
                    </div>
                </div>
            ))}
            </div>
        </div>
    );
};

export default LeaderboardTable;
