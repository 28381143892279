// src/theme/colors.js

const colors = {
    white: "#FFFFFF",
    lightGreen: "#A3E89F",
    darkGreen: "#047336",
    darkGreen30: "rgba(4, 115, 54, 0.30)",
    white10: "rgba(255, 255, 255, 0.10)",
    black: "#000000",
    black60: "rgba(0, 0, 0, 0.60)",
    gray: "#C4C2C2",
    grayProfile: "#1C1C1E",
    grayModal: "#2F2F2F",
    grayBorder: "#7A7A7A",
    pink: "#FF007A",
};

export default colors;
