// src/pages/Home/Home.tsx

import React, { useState } from 'react';
import styles from './Home.module.sass';
import PlayForFree from '../../components/PlayForFree/PlayForFree';
import PlayByBetting from '../../components/PlayByBetting/PlayByBetting';
import ConnectWallet from '../../components/ConnectWallet/ConnectWallet';

// 
import GetReady from '../../components/GetReady/GetReady';
import GameOver from '../../components/GameOver/GameOver';

const Home: React.FC = () => {
    const [showConnectWallet, setShowConnectWallet] = useState(false);

    const handlePlaceBet = () => {
        setShowConnectWallet(true);
    };

    const handleCloseWallet = () => {
        setShowConnectWallet(false);
    };

    return (
        <div className={styles.home_page_wrapper}>
            {/* <GameOver score={0} onNewGame={() => {}} onViewLeaderboard={() => {}} /> */}
            {/* <GetReady onStart={() => {}} /> */}
                
            <PlayForFree />
            <PlayByBetting onPlaceBet={handlePlaceBet} />
            {showConnectWallet && (
                <ConnectWallet onConnect={handleCloseWallet} onSkip={handleCloseWallet} />
            )}
        </div>
    );
};

export default Home;
