// src/theme/theme.js

import colors from './colors';

export const theme = {
  colors,
};

export const applyTheme = () => {
    const root = document.documentElement;

    Object.keys(colors).forEach((key) => {
        root.style.setProperty(`--color-${key}`, colors[key]);
    });
};
