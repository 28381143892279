// src/pages/Leaderboard/Leaderboard.tsx

import React from 'react';
import styles from './Leaderboard.module.sass';
import LeaderboardTable from '../../components/LeaderboardTable/LeaderboardTable';
import ProfileCard from '../../components/ProfileCard/ProfileCard';
import PrimaryButton from '../../components/PrimaryButton/PrimaryButton';

const participantsData = [
    { id: 1, name: 'JEBBERTJAN', score: 32675, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 1 },
    { id: 2, name: 'MANILLADEBILLA', score: 31988, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 2 },
    { id: 3, name: 'JESUSCRIST', score: 30897, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 3 },
    { id: 4, name: 'BOERENZOON27', score: 30776, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 4 },
    { id: 5, name: 'PLAYER5', score: 30500, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 5 },
    { id: 6, name: 'PLAYER6', score: 30250, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 6 },
    { id: 7, name: 'PLAYER7', score: 30000, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 7 },
    { id: 8, name: 'PLAYER8', score: 29750, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 8 },
    { id: 9, name: 'PLAYER9', score: 29500, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 9 },
    { id: 10, name: 'PLAYER10', score: 29250, avatar: 'https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ', position: 10 },
];

const totalParticipants = '211,442';

const Leaderboard: React.FC = () => {
    return <div className={styles.leaderboard_page_wrapper}>
        <div className="page_header min">
            <h2 className="page_header_title">your leaderboard score</h2>
            <p className="page_header_description">
                total 24.767 chewy
            </p>
        </div>
        <div className={styles.card_profile_wrapper}>
            <ProfileCard
                avatar="https://i.ytimg.com/vi/g2AECf0E-uo/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLD2V32QoQXCmS7T7d1bR9uKv1TjMQ"
                username="FAKAMOTOCHEWY"
                highestScore={28767}
                earnedPoints={4200}
                ranking={312}
            />
        </div>
        <div className={styles.buttons_wrapper}>
            <PrimaryButton type="primary_border" onClick={() => console.log('New Game')} className="min_font">
                New Game
            </PrimaryButton>
            <PrimaryButton type="primary_border" onClick={() => console.log('Invite Frens')} className="min_font">
                Invite Frens
            </PrimaryButton>
        </div>
        <div className={styles.leaderboard_list_wrapper}>
            <LeaderboardTable participants={participantsData} totalParticipants={totalParticipants} />
        </div>
    </div>;
};

export default Leaderboard;
