// src/components/PlayByBetting/PlayByBetting.tsx

import React, { useState } from 'react';
import styles from './PlayByBetting.module.sass';
import playByBettingImg from '../../assets/images/play_by_betting_img.jpg';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

interface PlayByBettingProps {
    onPlaceBet: () => void;
}

const PlayByBetting: React.FC<PlayByBettingProps> = ({ onPlaceBet }) => {
    const [isActive, setIsActive] = useState(false);

    const handleToggle = () => {
        if (!isActive) {
            setIsActive(true);
        }
    };

    return (
        <div
            className={`${styles.play_by_betting_wrapper} ${isActive ? styles.active : ''}`}
            onClick={handleToggle}
        >
            <img src={playByBettingImg} alt="Play by Betting" />
            <div className={`${styles.details_card} ${isActive ? styles.active : ''}`}>
                <h2 className={styles.title_card}>
                    play by betting and win usdt
                </h2>
                <div className={`${styles.hide_details} ${isActive ? styles.active : ''}`}>
                    <p className={styles.text_card}>
                        for every 10 usd bet you get 1 try to set a high score in the current season
                        <br />
                        <br />
                        a season lasts one week
                        <br />
                        <br />
                        high scores will be reset 
                        after every season 
                        <br />
                        <br />
                        <span>high score prize structure</span>
                        <br />
                        <br />
                        #1 wins 50% total prize pool
                        #2 wins 20% total prize pool
                        #3 wins 10% total prize pool
                        <br />
                        <br />
                        #4 to #10 win +1000 chewy
                    </p>
                    <PrimaryButton type="secondary_green" className="max_width" onClick={onPlaceBet}>
                        place bet
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};

export default PlayByBetting;
