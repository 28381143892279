// src/components/Button/PrimaryButton.tsx

import React from 'react';
import styles from './PrimaryButton.module.sass';

interface PrimaryButtonProps {
    children: React.ReactNode;
    onClick?: () => void;
    type: 'primary' | 'primary_border' | 'secondary' | 'secondary_green';
    disabled?: boolean;
    className?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ children, onClick, type, disabled, className }) => {
    const buttonClasses = [
        styles.primary_button,
        styles[type],
        disabled ? styles.disabled : '',
        className,
    ].join(' ').trim();

    return (
        <button 
            className={buttonClasses}
            onClick={onClick}
            disabled={disabled}
        >
            {children}
        </button>
    );
};

export default PrimaryButton;
