// src/App.tsx

import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme/theme';
import './styles/App.module.sass';
import Navigation from './components/Navigation/Navigation';
import SplashScreen from './components/SplashScreen/SplashScreen';
import Home from './pages/Home/Home';
import Leaderboard from './pages/Leaderboard/Leaderboard';
import Friends from './pages/Friends/Friends';
import Earn from './pages/Earn/Earn';

const App: React.FC = () => {
    const [showSplash, setShowSplash] = useState(true);

    const handleCloseSplash = () => {
        setShowSplash(false);
    };

    if (showSplash) {
        return <SplashScreen onClose={handleCloseSplash} />;
    }

    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/leaderboard" element={<Leaderboard />} />
                    <Route path="/friends" element={<Friends />} />
                    <Route path="/earn" element={<Earn />} />
                </Routes>
                <Navigation />
            </Router>
        </ThemeProvider>
    );
};

export default App;
