// src/components/PlayForFree/PlayForFree.tsx

import React from 'react';
import styles from './PlayForFree.module.sass';
import playForFreeImg from '../../assets/images/play_for_free_img.jpg';

const PlayForFree: React.FC = () => {
    return (
        <button className={styles.play_for_free_wrapper}>
            <img src={playForFreeImg} alt="Play for Free" />
            <div className={styles.details_card}>
                <h2 className={styles.title_card}>
                    play for free to earn chewy
                </h2>
                <p className={styles.text_card}>
                    your highest score will be converted to chewy tokens in airdrop
                </p>
            </div>
        </button>
    );
};

export default PlayForFree;
