// src/components/SplashScreen/SplashScreen.tsx

import React, { useEffect, useState } from 'react';
import styles from './SplashScreen.module.sass';
import PrimaryButton from '../PrimaryButton/PrimaryButton';

interface SplashScreenProps {
    onClose: () => void;
}

const SplashScreen: React.FC<SplashScreenProps> = ({ onClose }) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const loadTimer = setTimeout(() => {
            setIsLoaded(true);
        }, 3000);

        return () => clearTimeout(loadTimer);
    }, []);

    return (
        <div className={styles.splash_screen}>
            <div className={styles.splash_content}>
                <h1>Wooky bird</h1>
                <p>{isLoaded ? "play for free and win chewy tokens in our upcoming airdrop" : "Loading..."}</p>
            </div>
            {isLoaded && (
                <PrimaryButton onClick={onClose} type="primary" className={styles.splash_screen_styles}>
                    Start
                </PrimaryButton>
            )}
        </div>
    );
};

export default SplashScreen;
