// src/components/TaskList/TaskList.tsx

import React from 'react';
import styles from './TaskList.module.sass';

interface Task {
    id: number;
    icon: string;
    description: string;
    reward: string;
}

interface TaskListProps {
    tasks: Task[];
    totalReward: string;
    onTaskClick: (task: Task) => void;
}

const TaskList: React.FC<TaskListProps> = ({ tasks, totalReward, onTaskClick }) => {
    return (
        <div className={styles.task_list}>
            <div className={styles.header}>
                <h6 className={styles.heading_tasks}>TASKS LIST</h6>
                <span className={styles.total_reward}>{totalReward} TOTAL</span>
            </div>
            <div className={styles.tasks}>
                {tasks.map((task) => (
                    <div
                        className={styles.task}
                        key={task.id}
                        onClick={() => onTaskClick(task)}
                    >
                        <img src={task.icon} alt="" className={styles.icon} />
                        <span className={styles.description}>{task.description}</span>
                        <span className={styles.reward}>{task.reward}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default TaskList;
