// src/pages/Earn/Earn.tsx

import React, { useState } from 'react';
import styles from './Earn.module.sass';
import TaskList from '../../components/TaskList/TaskList';
import TaskModal from '../../components/TaskModal/TaskModal';

interface Task {
    id: number;
    icon: string;
    description: string;
    reward: string;
}

const tasksData: Task[] = [
    { id: 1, icon: 'https://upload.wikimedia.org/wikipedia/commons/4/42/YouTube_icon_%282013-2017%29.png', description: 'FOLLOW US ON YOUTUBE', reward: '300' },
    { id: 2, icon: 'https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg', description: 'JOIN OUR TELEGRAM', reward: '300' },
    { id: 3, icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a6/X_logo_2023.svg', description: 'FOLLOW OUR X ACCOUNT', reward: '300' },
    { id: 4, icon: 'https://upload.wikimedia.org/wikipedia/commons/a/a5/Instagram_icon.png', description: 'FOLLOW OUR INSTAGRAM', reward: '300' },
    { id: 5, icon: 'https://upload.wikimedia.org/wikipedia/commons/5/51/Facebook_f_logo_%282019%29.svg', description: 'LIKE OUR FACEBOOK PAGE', reward: '300' },
    { id: 6, icon: 'https://upload.wikimedia.org/wikipedia/commons/0/09/Twitter_bird_logo_2012.svg', description: 'RETWEET OUR POST', reward: '300' },
    { id: 7, icon: 'https://upload.wikimedia.org/wikipedia/commons/2/2a/LinkedIn_Logo.svg', description: 'FOLLOW US ON LINKEDIN', reward: '300' },
    { id: 8, icon: 'https://upload.wikimedia.org/wikipedia/commons/4/4f/TikTok_logo.svg', description: 'FOLLOW US ON TIKTOK', reward: '300' },
];
const totalReward = tasksData.reduce((sum, task) => sum + parseInt(task.reward, 10), 0).toString();

const Earn: React.FC = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedTask, setSelectedTask] = useState<Task | null>(null);

    const handleTaskClick = (task: Task) => {
        setSelectedTask(task);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <div className={styles.earn_page_wrapper}>
            <div className="page_header">
                <h2 className="page_header_title">earn extra chewy</h2>
                <p className="page_header_description">
                    complete tasks to earn extra chewy that will be added to your highscore
                </p>
            </div>
            <TaskList tasks={tasksData} totalReward={totalReward} onTaskClick={handleTaskClick} />
            {selectedTask && (
                <TaskModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    icon={selectedTask.icon}
                    title={selectedTask.description}
                    description="Complete this task to earn extra CHEWY on your highscore"
                    reward={selectedTask.reward}
                    onJoin={() => console.log('Joining task')}
                    onCheck={() => console.log('Checking task')}
                />
            )}
        </div>
    );
};

export default Earn;
