// src/components/FriendsList/FriendsList.tsx

import React from 'react';
import styles from './FriendsList.module.sass';

interface Friend {
    id: number;
    name: string;
    tokens: number;
    avatar: string;
}

interface FriendsListProps {
    friends: Friend[];
    totalTokens: number;
}

const FriendsList: React.FC<FriendsListProps> = ({ friends, totalTokens }) => {
    return (
        <div className={styles.friends_list}>
            <div className={styles.header}>
                <h6  className={styles.heading_friends}>{friends.length} friends</h6>
                <span className={styles.total_tokens}>{totalTokens} total</span>
            </div>
            <div className={styles.friends}>
                {friends.map((friend) => (
                    <div className={styles.friend} key={friend.id}>
                        <img src={friend.avatar} alt={`${friend.name}'s avatar`} className={styles.avatar} />
                        <span className={styles.name}>{friend.name}</span>
                        <span className={styles.tokens}>{friend.tokens} chewy</span>
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default FriendsList;
