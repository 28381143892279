import React, { useState } from 'react';
import styles from './ProfileCard.module.sass';

interface ProfileCardProps {
    avatar: string;
    username: string;
    highestScore: number;
    earnedPoints: number;
    ranking: number;
}

const ProfileCard: React.FC<ProfileCardProps> = ({ avatar, username, highestScore, earnedPoints, ranking }) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabChange = (index: number) => {
        setActiveTab(index);
    };

    return (
        <div className={styles.profileCard}>
            <div className={styles.profileCardContent}>
                <img src={avatar} alt={`${username}'s avatar`} className={styles.avatar} />
                <div className={styles.info}>
                    <h2 className={styles.username}>{username}</h2>
                    <p className={styles.details}>{highestScore.toLocaleString()} HIGHEST GAME SCORE</p>
                    <p className={styles.details}>{earnedPoints.toLocaleString()} EARNED & FRIENDS</p>
                </div>
                <div className={styles.ranking}>
                    <h3 className={styles.rankingTitle}>RANKING</h3>
                    <p className={styles.rank}>#{ranking}</p>
                </div>
            </div>

            <div className={styles.tabs}>
                <button
                    className={`${styles.tabButton} ${activeTab === 0 ? styles.active : ''}`}
                    onClick={() => handleTabChange(0)}
                >
                    Chewy Score
                </button>
                <button
                    className={`${styles.tabButton} ${activeTab === 1 ? styles.active : ''}`}
                    onClick={() => handleTabChange(1)}
                >
                    Betting Score
                </button>
            </div>
        </div>
    );
};

export default ProfileCard;
